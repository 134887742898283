













































import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import campos_personalizadoModule from "@/store/modules/campos_personalizado-module.ts";
import { RouterNames } from "@/router/routernames";
import { UtilsEncript } from "@/utils/utils-encript";
import dieta_visualizacionModule from "@/store/modules/visualizacion_dieta/dieta_visualizacion-module";
import { dietaDto } from "@/shared/dtos/visualizacion_dieta/dietaDto";

@Component({
  components: {
    AddCitaPaciente: () => import("@/views/calendario/AddCitaPaciente.vue"),
    CompDialogObjetivos: () =>
      import("@/views/entrevista/dialogos/CompDialogObjetivos.vue"),
    Seleccionplantillas: () =>
      import("@/views/plantillas/seleccion-plantillas.vue")
  }
})
export default class CompSelectFinalEntrevista extends Vue {
  @PropSync("dialog") dialogsync!: boolean;
  @Prop() id_paciente!: number;
  @Prop() id_entrevista!: number;
  @Prop() id_cita!: number;
  @Prop() Edicion!: boolean;
  @Prop({ default: [] }) OcultarCampos!: number[];
  @Prop({ default: [] }) AddCampos!: any[];
  public fecha_cita = new Date();
  public AddCitaShow = false;
  public AddObjetivosShow = false;
  public btns: any = [];
  public show_select_platilla: boolean = false;
  public async created() {
    this.allbuttons;
    await campos_personalizadoModule.getcampos_personalizados();
  }

  public get allbuttons() {
    this.btns = [
      {
        id: 1,
        titulo: "Imprimir en PDF",
        descripcion: "¿Queire imprimir la consulta en PDF?",
        icono: "fad fa-file-pdf",
        visible: this.OcultarCampos.filter(x => x === 1).length === 0
      },
      {
        id: 2,
        titulo: "Terminar",
        descripcion: "Cerrar consulta y ir a inicio",
        icono: "fad fa-home-lg",
        visible: this.OcultarCampos.filter(x => x === 2).length === 0
      }
    ];
    if (campos_personalizadoModule.campos_personalizados.length > 0) {
      if (!this.Edicion) {
        this.btns.push({
          id: 3,
          titulo: "Mediciones",
          descripcion:
            "Continuar midiendo al paciente con las mediciones personalizadas",
          icono: "fad fa-adjust",
          visible: this.OcultarCampos.filter(x => x === 3).length === 0
        });
      }
      this.btns.push({
        id: 5,
        titulo: "Objetivos",
        descripcion:
          "Continuar creando objetivos a alcanzar de las mediciones personalizadas",
        icono: "fad fa-bullseye-arrow",
        visible: this.OcultarCampos.filter(x => x === 5).length === 0
      });
    }
    this.btns.push({
      id: 4,
      titulo: "Cita",
      descripcion: "Coger una nueva cita para el paciente",
      icono: "fad fa-calendar-alt",
      visible: this.OcultarCampos.filter(x => x === 4).length === 0
    });
    this.btns.push({
      id: 6,
      titulo: "Dieta automática",
      descripcion:
        "Continuar creando dieta el sistema realizará una dieta personalizada para el paciente",
      icono: "fad fa-magic",
      visible: this.OcultarCampos.filter(x => x === 6).length === 0
    });
    this.btns.push({
      id: 8,
      titulo: "Dieta plantilla",
      descripcion: "Continuar asignado una plantilla de dieta al paciente",
      icono: "fad fa-apple-crate",
      visible: this.OcultarCampos.filter(x => x === 8).length === 0
    });
    this.btns.push({
      id: 7,
      titulo: "Dieta manual",
      descripcion:
        "Continuar creando dieta personalizada para el paciente manualmente",
      icono: "fad fa-hand-pointer",
      visible: this.OcultarCampos.filter(x => x === 7).length === 0
    });
    for (let i = 0; i < this.AddCampos.length; i++) {
      this.btns.push(this.AddCampos[i]);
    }
    return this.btns;
  }

  /*@Watch("AddCampos")
  public add_todos_campos(){
    this.btns=[];
   let btns this.allbuttons

  }*/

  public clickbutton(item: any) {
    switch (item.id) {
      case 1:
        this.$emit("imprimir_pdf");
        break;
      case 2:
        this.$router.push({
          name: RouterNames.Home
        });
        break;
      case 3:
        this.$router.push({
          name: RouterNames.mediciones_avanzadas_entrevista,
          query: {
            id_paciente: UtilsEncript.Encriptar(this.id_paciente.toString())
          }
        });
        break;
      case 4:
        this.AddCitaShow = true;
        break;
      case 5:
        this.AddObjetivosShow = true;
        break;
      case 6:
        /*
          public id_paciente: number = 2;
  public id_cita: number = 0;
  public id_entrevista: number = 623;
        */
        this.$router.push({
          name: RouterNames.asistente_dieta_automatica,
          query: {
            id_cita: UtilsEncript.Encriptar(this.id_cita.toString()),
            id_entrevista: UtilsEncript.Encriptar(
              this.id_entrevista.toString()
            ),
            id_paciente: UtilsEncript.Encriptar(this.id_paciente.toString())
          }
        });
        break;
      case 7:
        dieta_visualizacionModule
          .getIdDieta(this.id_paciente)
          .then((x: dietaDto) => {
            this.$router.push({
              name: RouterNames.dieta,
              query: {
                id_dieta: UtilsEncript.Encriptar(x.id.toString()),
                id_paciente: UtilsEncript.Encriptar(x.paciente.toString())
              }
            });
          });
        break;
      case 8:
        this.show_select_platilla = true;
        break;
      default:
        this.$emit("seleccionar", item);
    }
  }
}
